header.wrapper {
  border-top: 10px solid $purple;
  padding-top: 24px;
  padding-bottom: 24px;
  position: relative;

  .title {
    text-align: center;
    position: relative;
    left: 0px;
    top: 12px;
    a {
      //@include sprite-replace-text-with-dimensions($icons, master-logo-small);
      border: 0;
      font: 0 / 0 a;
      text-shadow: none;
      color: transparent;
      display: block;
      overflow: hidden;

      background: transparent url('/assets/layout/icons/master-logo-small.png') 50% 50% scroll;
      height: 70px;
      width: 275px;

      @include media(850px) {
        background: transparent url('/assets/layout/icons/master-logo.png') 50% 50% scroll;
        height: 88px;
        width: 355px;
      }
    }
  }

  .subtext {
    display: none;
    position: absolute;
    top: 73px;
    right: 54px;
    text-transform: uppercase;
    font-size: 13px;
    padding-left: 24px;
    font-weight: normal;
    line-height: 21px;
    @include media(800px) {
      display: block;
    }
  }

}