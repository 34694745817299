img.img__half {
  width: 100%;
  @include media(500px) {
    width: 50%;
  }
}

img.img__third {
  width: 100%;
  @include media(500px) {
    width: 33%;
  }
}

img.img__two-thirds{
  width: 100%;
  @include media(500px) {
    width: 66%;
  }
}

img.img__full {
  width: 100%;
}

.img__left {
  @include media(500px) {
    margin-right: 18px;
    float: left;
  }
}

.img__right{
  @include media(500px) {
    margin-left: 18px;
    float: right;
  }
}