.hero-slideshow {
  padding: 0 0 30px 0;
  margin: 0;
  position: relative;

  .slide {
    height: 250px;
    overflow: hidden;
    img {
      width: auto;
      min-height: 100%;
    }

    .slide-text {
      position: absolute;
      bottom: 25%;
      left: 0;
      width: auto;
      background: rgba(255, 255, 255, 0.9);
      padding: 10px 20px 20px;

      h2 {
        line-height: 1;
        margin: 0 0 8px;
        font-size: 24px;
        padding: 0;
        font-weight: 800;
      }

      p {
        line-height: 1;
        margin: 0 0 12px;
        padding: 0;
        font-size: 18px;
      }
      a.call-to-action {
        display: inline-block;
        padding: 6px 24px;
        background-color: $red;
        color: #fff;
        text-transform: uppercase;
        font-size: 14px;

        &:hover {
          background-color: lighten($red, 15%);
        }
      }
    }
  }
}

//.hero-slideshow {
//
//  padding: 0 0 30px 0;
//  margin: 0;
//
//  .slide {
//    position: relative;
//    .slide-text {
//
//      background-color: #ECEDEF;
//      padding: 10px;
//
//      h2 {
//        line-height: 1 !important;
//        margin: 0;
//        font-size: 20px;
//        padding: 0;
//      }
//
//      p {
//        line-height: 1;
//        margin: 0 0 12px;
//        padding: 0;
//        font-size: 18px;
//      }
//
//      a.call-to-action {
//        display: inline-block;
//        padding: 6px 24px;
//        background-color: $red;
//        color: #fff;
//        text-transform: uppercase;
//        font-size: 14px;
//
//        &:hover {
//          background-color: lighten($red, 15%);
//        }
//      }
//    }
//  }
//}