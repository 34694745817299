@import "../node_modules/bourbon/app/assets/stylesheets/bourbon";
@import "bitters/base";
@import "../node_modules/bourbon-neat/app/assets/stylesheets/neat";

body {
  background: #e1e2e4 url(/assets/layout/bg-sliver.jpg) repeat-x center top scroll;
  text-rendering: optimizeLegibility;
}

@import "structure";
@import "nav";
@import "form";
@import "page-heading";
@import "hero";
@import "footer";
@import "img";
@import "newsletter";

.divider {
  @include row();
  clear: both;
  height: 4px;
  background: transparent url(/assets/layout/dotted-divider.png) repeat-x center top scroll;
}
.home .primary-content {
  padding-top: 24px;
}
.primary-content .divider {
  margin: 24px 0;
}