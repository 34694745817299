ul,
ol {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

dl {
  margin: 0;
}

dt {
  font-weight: 600;
  margin: 0;
}

dd {
  margin: 0;
}

.primary-content ul {
  list-style-type: disc;
  margin-bottom: 1em;

  li {
    margin-left: 18px;
  }

}

.primary-content ol {
  list-style-type: decimal;
  margin-bottom: 1em;

  li {
    margin-left: 18px;
  }
}