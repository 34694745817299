
// Breakpoints
$medium-screen: 600px;
$large-screen: 900px;

// Typography
$base-font-family:  "Helvetica Neue", Helvetica, Arial, sans-serif;
$heading-font-family: $base-font-family;

// Font Sizes
$base-font-size: 1em;

// Line height
$base-line-height: 1.7;
$heading-line-height: 1.7;

// Other Sizes
$base-border-radius: 3px;
$base-spacing: $base-line-height * 1em;
$small-spacing: $base-spacing / 2;
$base-z-index: 0;

// Colors
$blue: #1565c0;
$dark-gray: #333;
$medium-gray: #999;
$light-gray: #ddd;

$purple: #192177;
$red: #7D1417;
$text: #585858;
$link-normal: $red;
$link-hover: darken($red, 9%);
$link-active: lighten($red, 5%);
$link-visited: darken($red, 3%);
$link-focus: lighten($red, 5%);


// Font Colors
$base-font-color: $dark-gray;
$action-color: $red;

// Border
$base-border-color: $light-gray;
$base-border: 1px solid $base-border-color;

// Background Colors
$base-background-color: #fff;
$secondary-background-color: tint($base-border-color, 75%);

// Forms
$form-box-shadow: inset 0 1px 3px rgba(#000, 0.06);
$form-box-shadow-focus: $form-box-shadow, 0 0 5px adjust-color($action-color, $lightness: -5%, $alpha: -0.3);

// Animations
$base-duration: 150ms;
$base-timing: ease;
