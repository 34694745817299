.placeholder { color: #aaa; }

label {
  display: none;
  float: left;
  margin: 0px 0px 5px;
}

.username {
  display: none;
}
.alert-errors {
  margin-bottom: 24px;
  background-color: #ba9800;
  padding: 18px;
  border-radius: 4px;
  color: white;
  p {
    font-weight: bold;
    color: white;
    margin: 0;
  }
}
.alert-success {
  background-color: #192177;
  margin-bottom: 24px;
  padding: 1px 16px;
  color: #fff;
  border-radius: 4px;
}