.container {
  @include outer-container();

   @include media(600px) {
     padding: 0 30px;
   }
}

.wrapper {
  background-color: #fff;
  @include row();
  padding: 0 24px;
  @include media(800px) {
    padding: 0 50px;
  }
}

.primary-content-wrapper {
  @include fill-parent;

  @include media(800px) {
    @include span-columns(12 of 16);
  }

  .primary-content {
    @include media(800px) {
      margin-right: 36px;
    }
  }

  .featured-content-outer {
    @include row();
    margin-bottom: 24px;
  }
  .featured-content-block-wrapper {
    @include fill-parent;
    @include media(600px) {
      @include span-columns(6 of 12);
      @include omega(2n);
    }
  }

  .featured-content {
    @include media(600px) {
      margin-right: 12px;
    }
  }
}

.secondary-content-wrapper {
  margin-top: 50px;
  @include media(800px){
    @include span-columns(4 of 16);
  }
}

//@include media(600px) {
//  .wrapper {
//    margin: 0 0 - $gutter;
//    padding: 0 $gutter;
//    @include row();
//    background-color: #fff;
//  }
//
//  header.wrapper {
//    .subtext {
//      margin-top: 61px;
//      float: right;
//      text-transform: uppercase;
//      font-size: 13px;
//      display: block;
//      padding-left: 24px;
//      font-weight: normal;
//      line-height: 21px;
//    }
//
//    nav ul {
//      li {
//        margin-right: 12px;
//      }
//    }
//
//    .title {
//      float: left;
//      a {
//        //@include sprite-replace-text-with-dimensions($icons, master-logo);
//        display: block;
//      }
//    }
//  }

//
//  .primary-content-wrapper {
//    @include row();
//
//    .featured-content-block-wrapper {
//      @include span-columns(3 of 9);
//      @include omega(3n);
//    }
//  }
//
//  .secondary-content-wrapper {
//    @include row();
//    .secondary-content {
//      /*padding-left: 24px;*/
//    }
//  }
//}

//@include media(800px) {
//
//  .primary-content-wrapper {
//    @include span-columns(9 of 12);
//    .featured-content-block-wrapper {
//      @include span-columns(3 of 9);
//      @include omega(3n);
//    }
//  }
//
//  .secondary-content-wrapper {
//    @include span-columns(3 of 12);
//  }
//
//
//  .form-name,
//  .form-email,
//  .form-phone {
//    @include span-columns(4 of 12);
//    input {
//      width: 100%;
//    }
//  }
//
//  .form-phone {
//    @include omega();
//  }
//
//  .form-message {
//    textarea {
//      width: 100%;
//    }
//  }
//
//  .content-layout {
//
//    .primary-content-wrapper {
//      @include span-columns(8 of 12);
//    }
//
//    .secondary-content-wrapper {
//      @include span-columns(4 of 12);
//
//      .secondary-content {
//        margin-top: 100px;
//
//      }
//    }
//    .sidebar-blocks {
//      margin-bottom: 36px;
//      .sidebar-block {
//        .sidebar-block-image {
//          float: left;
//          margin-top: 5px;
//          margin-right: 12px;
//        }
//
//        .sidebar-block-text {
//          /*margin-left: 75px;*/
//        }
//      }
//
//    }
//  }
//}