.btn, button, input[type="button"], input[type="reset"], input[type="submit"] {
  appearance: none;
  background-color: #7D1417;
  border: 0;
  border-radius: 3px;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 1em;
  -webkit-font-smoothing: antialiased;
  font-weight: 600;
  line-height: 1;
  padding: 0.85em 1.7em;
  text-align: center;
  text-decoration: none;
  transition: background-color 150ms ease;
  user-select: none;
  vertical-align: middle;
  white-space: nowrap; }
  .btn:hover, .btn:focus, button:hover, button:focus, input[type="button"]:hover, input[type="button"]:focus, input[type="reset"]:hover, input[type="reset"]:focus, input[type="submit"]:hover, input[type="submit"]:focus {
    background-color: #641012;
    color: #fff; }
  .btn:disabled, button:disabled, input[type="button"]:disabled, input[type="reset"]:disabled, input[type="submit"]:disabled {
    cursor: not-allowed;
    opacity: 0.5; }
    .btn:disabled:hover, button:disabled:hover, input[type="button"]:disabled:hover, input[type="reset"]:disabled:hover, input[type="submit"]:disabled:hover {
      background-color: #7D1417; }

fieldset {
  background-color: transparent;
  border: 0;
  margin: 0;
  padding: 0; }

legend {
  font-weight: 600;
  margin-bottom: 0.425em;
  padding: 0; }

label {
  display: block;
  font-weight: 600;
  margin-bottom: 0.425em; }

input,
select,
textarea {
  display: block;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 1em; }

input[type="color"], input[type="date"], input[type="datetime"], input[type="datetime-local"], input[type="email"], input[type="month"], input[type="number"], input[type="password"], input[type="search"], input[type="tel"], input[type="text"], input[type="time"], input[type="url"], input[type="week"], input:not([type]), textarea {
  appearance: none;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 3px;
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.06);
  box-sizing: border-box;
  margin-bottom: 0.85em;
  padding: 0.5666666667em;
  transition: border-color 150ms ease;
  width: 100%; }
  input[type="color"]:hover, input[type="date"]:hover, input[type="datetime"]:hover, input[type="datetime-local"]:hover, input[type="email"]:hover, input[type="month"]:hover, input[type="number"]:hover, input[type="password"]:hover, input[type="search"]:hover, input[type="tel"]:hover, input[type="text"]:hover, input[type="time"]:hover, input[type="url"]:hover, input[type="week"]:hover, input:not([type]):hover, textarea:hover {
    border-color: #b1b1b1; }
  input[type="color"]:focus, input[type="date"]:focus, input[type="datetime"]:focus, input[type="datetime-local"]:focus, input[type="email"]:focus, input[type="month"]:focus, input[type="number"]:focus, input[type="password"]:focus, input[type="search"]:focus, input[type="tel"]:focus, input[type="text"]:focus, input[type="time"]:focus, input[type="url"]:focus, input[type="week"]:focus, input:not([type]):focus, textarea:focus {
    border-color: #7D1417;
    box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.06), 0 0 5px rgba(103, 16, 19, 0.7);
    outline: none; }
  input[type="color"]:disabled, input[type="date"]:disabled, input[type="datetime"]:disabled, input[type="datetime-local"]:disabled, input[type="email"]:disabled, input[type="month"]:disabled, input[type="number"]:disabled, input[type="password"]:disabled, input[type="search"]:disabled, input[type="tel"]:disabled, input[type="text"]:disabled, input[type="time"]:disabled, input[type="url"]:disabled, input[type="week"]:disabled, input:not([type]):disabled, textarea:disabled {
    background-color: #f2f2f2;
    cursor: not-allowed; }
    input[type="color"]:disabled:hover, input[type="date"]:disabled:hover, input[type="datetime"]:disabled:hover, input[type="datetime-local"]:disabled:hover, input[type="email"]:disabled:hover, input[type="month"]:disabled:hover, input[type="number"]:disabled:hover, input[type="password"]:disabled:hover, input[type="search"]:disabled:hover, input[type="tel"]:disabled:hover, input[type="text"]:disabled:hover, input[type="time"]:disabled:hover, input[type="url"]:disabled:hover, input[type="week"]:disabled:hover, input:not([type]):disabled:hover, textarea:disabled:hover {
      border: 1px solid #ddd; }
  input[type="color"]::placeholder, input[type="date"]::placeholder, input[type="datetime"]::placeholder, input[type="datetime-local"]::placeholder, input[type="email"]::placeholder, input[type="month"]::placeholder, input[type="number"]::placeholder, input[type="password"]::placeholder, input[type="search"]::placeholder, input[type="tel"]::placeholder, input[type="text"]::placeholder, input[type="time"]::placeholder, input[type="url"]::placeholder, input[type="week"]::placeholder, input:not([type])::placeholder, textarea::placeholder {
    color: #858585; }

textarea {
  resize: vertical; }

[type="checkbox"],
[type="radio"] {
  display: inline;
  margin-right: 0.425em; }

[type="file"] {
  margin-bottom: 0.85em;
  width: 100%; }

select {
  margin-bottom: 0.85em;
  width: 100%; }

html {
  box-sizing: border-box; }

*,
*::before,
*::after {
  box-sizing: inherit; }

html,
body {
  height: 100%; }

ul,
ol {
  list-style-type: none;
  margin: 0;
  padding: 0; }

dl {
  margin: 0; }

dt {
  font-weight: 600;
  margin: 0; }

dd {
  margin: 0; }

.primary-content ul {
  list-style-type: disc;
  margin-bottom: 1em; }
  .primary-content ul li {
    margin-left: 18px; }

.primary-content ol {
  list-style-type: decimal;
  margin-bottom: 1em; }
  .primary-content ol li {
    margin-left: 18px; }

figure {
  margin: 0; }

img,
picture {
  margin: 0;
  max-width: 100%; }

table {
  border-collapse: collapse;
  margin: 0.85em 0;
  table-layout: fixed;
  width: 100%; }

th {
  border-bottom: 1px solid #a6a6a6;
  font-weight: 600;
  padding: 0.85em 0;
  text-align: left; }

td {
  border-bottom: 1px solid #ddd;
  padding: 0.85em 0; }

tr,
td,
th {
  vertical-align: middle; }

body {
  color: #333;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 1em;
  line-height: 1.7; }

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 1.333em;
  line-height: 1.7;
  margin: 0 0 0.85em;
  color: #192177; }

h1 {
  font-size: 1.75em; }

h2 {
  font-size: 1.5em; }

h3 {
  font-size: 1.25em; }

h4 {
  font-size: 1.1em; }

h5 {
  font-size: 1.1em; }

h6 {
  font-size: 1em; }

p {
  margin: 0 0 0.85em; }

a {
  color: #7D1417;
  text-decoration: none;
  transition: color 150ms ease; }
  a:active, a:focus, a:hover {
    color: #5e0f11; }

hr {
  border-bottom: 1px solid #ddd;
  border-left: 0;
  border-right: 0;
  border-top: 0;
  margin: 1.7em 0; }

html {
  box-sizing: border-box; }

*, *::after, *::before {
  box-sizing: inherit; }

body {
  background: #e1e2e4 url(/assets/layout/bg-sliver.jpg) repeat-x center top scroll;
  text-rendering: optimizeLegibility; }

.container {
  max-width: 68em;
  margin-left: auto;
  margin-right: auto; }
  .container::after {
    clear: both;
    content: "";
    display: table; }
  @media screen and (min-width: 600px) {
    .container {
      padding: 0 30px; } }

.wrapper {
  background-color: #fff;
  display: block;
  padding: 0 24px; }
  .wrapper::after {
    clear: both;
    content: "";
    display: table; }
  @media screen and (min-width: 800px) {
    .wrapper {
      padding: 0 50px; } }

.primary-content-wrapper {
  width: 100%; }
  @media screen and (min-width: 800px) {
    .primary-content-wrapper {
      float: left;
      display: block;
      margin-right: 1.7578775415%;
      width: 74.5605306146%; }
      .primary-content-wrapper:last-child {
        margin-right: 0; } }
  @media screen and (min-width: 800px) {
    .primary-content-wrapper .primary-content {
      margin-right: 36px; } }
  .primary-content-wrapper .featured-content-outer {
    display: block;
    margin-bottom: 24px; }
    .primary-content-wrapper .featured-content-outer::after {
      clear: both;
      content: "";
      display: table; }
  .primary-content-wrapper .featured-content-block-wrapper {
    width: 100%; }
    @media screen and (min-width: 600px) {
      .primary-content-wrapper .featured-content-block-wrapper {
        float: left;
        display: block;
        margin-right: 2.3576515979%;
        width: 48.821174201%; }
        .primary-content-wrapper .featured-content-block-wrapper:last-child {
          margin-right: 0; }
        .primary-content-wrapper .featured-content-block-wrapper:nth-child(2n) {
          margin-right: 0; }
        .primary-content-wrapper .featured-content-block-wrapper:nth-child(2n+1) {
          clear: left; } }
  @media screen and (min-width: 600px) {
    .primary-content-wrapper .featured-content {
      margin-right: 12px; } }

.secondary-content-wrapper {
  margin-top: 50px; }
  @media screen and (min-width: 800px) {
    .secondary-content-wrapper {
      float: left;
      display: block;
      margin-right: 1.7578775415%;
      width: 23.6815918439%; }
      .secondary-content-wrapper:last-child {
        margin-right: 0; } }

nav ul {
  display: block;
  margin: 12px 0;
  padding: 0; }
  nav ul li {
    display: inline-block;
    margin-right: 6px;
    padding: 6px; }
    nav ul li a.current {
      font-weight: bold; }

.placeholder {
  color: #aaa; }

label {
  display: none;
  float: left;
  margin: 0px 0px 5px; }

.username {
  display: none; }

.alert-errors {
  margin-bottom: 24px;
  background-color: #ba9800;
  padding: 18px;
  border-radius: 4px;
  color: white; }
  .alert-errors p {
    font-weight: bold;
    color: white;
    margin: 0; }

.alert-success {
  background-color: #192177;
  margin-bottom: 24px;
  padding: 1px 16px;
  color: #fff;
  border-radius: 4px; }

header.wrapper {
  border-top: 10px solid #192177;
  padding-top: 24px;
  padding-bottom: 24px;
  position: relative; }
  header.wrapper .title {
    text-align: center;
    position: relative;
    left: 0px;
    top: 12px; }
    header.wrapper .title a {
      border: 0;
      font: 0 / 0 a;
      text-shadow: none;
      color: transparent;
      display: block;
      overflow: hidden;
      background: transparent url("/assets/layout/icons/master-logo-small.png") 50% 50% scroll;
      height: 70px;
      width: 275px; }
      @media screen and (min-width: 850px) {
        header.wrapper .title a {
          background: transparent url("/assets/layout/icons/master-logo.png") 50% 50% scroll;
          height: 88px;
          width: 355px; } }
  header.wrapper .subtext {
    display: none;
    position: absolute;
    top: 73px;
    right: 54px;
    text-transform: uppercase;
    font-size: 13px;
    padding-left: 24px;
    font-weight: normal;
    line-height: 21px; }
    @media screen and (min-width: 800px) {
      header.wrapper .subtext {
        display: block; } }

.hero-slideshow {
  padding: 0 0 30px 0;
  margin: 0;
  position: relative; }
  .hero-slideshow .slide {
    height: 250px;
    overflow: hidden; }
    .hero-slideshow .slide img {
      width: auto;
      min-height: 100%; }
    .hero-slideshow .slide .slide-text {
      position: absolute;
      bottom: 25%;
      left: 0;
      width: auto;
      background: rgba(255, 255, 255, 0.9);
      padding: 10px 20px 20px; }
      .hero-slideshow .slide .slide-text h2 {
        line-height: 1;
        margin: 0 0 8px;
        font-size: 24px;
        padding: 0;
        font-weight: 800; }
      .hero-slideshow .slide .slide-text p {
        line-height: 1;
        margin: 0 0 12px;
        padding: 0;
        font-size: 18px; }
      .hero-slideshow .slide .slide-text a.call-to-action {
        display: inline-block;
        padding: 6px 24px;
        background-color: #7D1417;
        color: #fff;
        text-transform: uppercase;
        font-size: 14px; }
        .hero-slideshow .slide .slide-text a.call-to-action:hover {
          background-color: #bf1f23; }

footer.wrapper .footer-inner {
  padding: 24px 0; }
  footer.wrapper .footer-inner p {
    text-align: right;
    font-size: 11px;
    margin: 24px 0 24px;
    color: #a5a5a5;
    padding: 0; }

img.img__half {
  width: 100%; }
  @media screen and (min-width: 500px) {
    img.img__half {
      width: 50%; } }

img.img__third {
  width: 100%; }
  @media screen and (min-width: 500px) {
    img.img__third {
      width: 33%; } }

img.img__two-thirds {
  width: 100%; }
  @media screen and (min-width: 500px) {
    img.img__two-thirds {
      width: 66%; } }

img.img__full {
  width: 100%; }

@media screen and (min-width: 500px) {
  .img__left {
    margin-right: 18px;
    float: left; } }

@media screen and (min-width: 500px) {
  .img__right {
    margin-left: 18px;
    float: right; } }

.newsletter-signup-wrapper {
  margin-top: 50px; }

.divider {
  display: block;
  clear: both;
  height: 4px;
  background: transparent url(/assets/layout/dotted-divider.png) repeat-x center top scroll; }
  .divider::after {
    clear: both;
    content: "";
    display: table; }

.home .primary-content {
  padding-top: 24px; }

.primary-content .divider {
  margin: 24px 0; }
