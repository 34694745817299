footer.wrapper {
  .footer-inner {
    padding: 24px 0;
    p {
      text-align: right;
      font-size: 11px;
      margin: 24px 0 24px;
      color: lighten($text, 30%);
      padding: 0;
    }
  }
}