nav ul {
  display: block;
  margin: 12px 0;
  padding: 0;
  li {
    display: inline-block;
    margin-right: 6px;
    padding: 6px;
    a.current {
      font-weight:bold;
    }
  }
}
